import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
const carousels = require("./carousels");
import VueScrollClass from "vue-scroll-class";

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
  },
  mounted() {
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselGridSlide();
    carousels.carouselCover();
  },
  directives: {
    "scroll-class": VueScrollClass,
  },
});
